/**
 *	Styles
 */

@import "settings/index";
@import "tools/index";
@import "generic/index";
@import "elements/index";
@import "objects/index";
@import "components/index";
@import "vendors/index";
@import "utilities/index";
@import "node_modules/bootstrap/scss/utilities/api";
@import "custom/index";

.editable-input {
  border: none;
  outline: none;
  border-radius: 5px;

  &:focus {
    border: 1px solid rgb(133, 133, 133);
  }
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.rnc__notification-timer {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.last-week-text-style {
  float: right;
  font-size: 9px;
  font-weight: 500;
  margin-top: -5px;
  color: #999999;
}

